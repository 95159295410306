import React, { Component } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { FormControl } from 'react-reactive-form';
import './IntroVideo.scss';
import ReactPlayer from 'react-player'

export interface IntroVideoProps {

}

export interface IntroVideoState {
  width?: string | number,
  height?: string | number,
  playState?: boolean,
}


class IntroVideo extends React.Component<IntroVideoProps, IntroVideoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      width: '100%',
      height: '100%',
      playState: false
    };
  }

  render() {
    const setPlay = () => {
      this.setState({ playState: !this.state.playState })
    }
    return (
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          width={this.state.width}
          height={this.state.height}
          url={localStorage.getItem("introVideo") || ""}
          // url='https://vimeo.com/243556536'
          playing={this.state.playState}
          onStart={setPlay}
          controls
        />
      </div>
    )
  }

}

export default IntroVideo