import React from 'react';
//@ts-ignore
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';
import Icon from '@material-ui/core/Icon';
import "./LocationSearchInput.scss"
import { FormControl } from 'react-reactive-form';
//@ts-ignore
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';

var address: any;
export interface LocationSearchInputProps {
  path?: string;
  location?: any;
  formControl?: FormControl;

}

export interface LocationSearchInputState {
  loading: boolean;
  address: any;
  errorMessage: any;
  latitude: any;
  longitude: any
  isGeocoding: any,
}

class LocationSearchInput extends React.Component<LocationSearchInputProps, LocationSearchInputState> {

  constructor(props: LocationSearchInputProps) {
    super(props);
    this.state = {
      loading: true,
      address: '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
    }

  }

  handleChange = (address: any) => {

    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  }

  searchOptions = {
    componentRestrictions: {country: "us"},
    types: ['address']
  }

  handleSelect = (address: any) => {
    localStorage.removeItem("currentZipCode")
    geocodeByAddress(address)
      .then((results: any) => {
        var add = results[0]
        console.log(add)
        var spl = add.formatted_address.split(",")
        var address = spl;
        var zip = spl[2].split(' ')
        console.log(zip)

        if (zip.length === 3) {
          localStorage.setItem("currentZipCode", zip[2]);
          console.log(zip)
          this.setState({ address: results[0].formatted_address });
          if (this.props.formControl) {
            this.props.formControl.setValue(results[0].formatted_address)
            this.props.formControl.markAsTouched();
          }
        }
        else{
          localStorage.removeItem("currentZipCode");
          alert("zip code not found")
        }
   
      
        
      }
      )
  };
  handleFocus = (event:any) => event.target.select();
  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={this.searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
          <div className="location-input">
            <TextField
              {...getInputProps({
                // placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              // FormHelperTextProps={{style: {fontSize: '1.5em', textAlign: 'right', color:'#CE526D'}}}
              margin="normal"
              label={"Address"}
              fullWidth
              onFocus={this.handleFocus}
            />
            {/* <input
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                  })}
                /> */}
            <div className="autocomplete-dropdown-container">
              {loading && <div className="loading-option">Loading...</div>}
              {suggestions.map((suggestion: any) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer', fontSize: "1.6em" }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: "1.6em" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

}

export default LocationSearchInput 