import React from "react";
import { MoveImage } from "components/image";
import { logo } from 'assets/images';
import './SignUp.scss';
import { TextField } from "components/textField";
import { Button } from "components/button";
//@ts-ignore
// import SimpleLineIcon from 'react-simple-line-icons';
import { FaFacebookF, FaGooglePlusG } from 'react-icons/fa';
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { navigate } from "@reach/router";



export interface SignUpProps {
    path?: string;
    field?: any;
    style?: any;
    onClick?: void;

}

export interface SignUpState {

}

class SignUp extends React.Component<SignUpProps, SignUpState> {
    constructor(props: SignUpProps) {
        super(props);
        this.state = {};
        clearAllBodyScrollLocks();

    }
    handleClick() {
        navigate('/login')
    }
    render() {
        return (
            <div className="singup-background">
                <div id="signup-container">
                    <div className="signin-logo">
                        <MoveImage className="logo" src={logo} width={'66.4%'}></MoveImage>
                    </div>
                    <div className="text-field">
                        <div className="full-name">
                            <TextField
                                label={'Full Name'}
                                keyboardType={'text'}
                            />
                        </div>
                        <div className="email">
                            <TextField
                                label={'Email'}
                                keyboardType={'email'}
                            />
                        </div>
                        <div className="password">
                            <TextField
                                label={'Password'}
                                keyboardType={'password'}
                            />
                        </div>
                    </div>
                    <div className="signup-button">
                        <Button
                            text={'Create an account'}
                            width={'77.3%'}
                            height={'7vh'}
                            fontSize={'1.7rem'}
                            fontWeight={'700'}

                        />
                    </div>
                    <div className="line-view">
                        <div className="social-seperator"></div>
                        <div className="info-text">
                            <p> or Signup with Social</p>
                        </div>
                        <div className="social-seperator"></div>
                    </div>
                    <div className="social-btn">
                        <div className="google"><FaGooglePlusG /></div>
                        <Button
                            text={'Google'}
                            width={'95%'}
                            height={'7vh'}
                            fontSize={'1.8rem'}
                            backgroundColor={'#DC4E41'}
                            fontWeight={'bold'}
                            textTransform={'capitalize'}

                        />
                        <div className="facebook"><FaFacebookF /></div>
                        <Button
                            text={'Facebook'}
                            width={'95%'}
                            height={'7vh'}
                            fontSize={'1.8rem'}
                            backgroundColor={'#2553B4'}
                            fontWeight={'bold'}
                            textTransform={'capitalize'}

                        />

                    </div>

                    <div className="signup-info">
                        <p>Have an account?<span className="signup-text"><a onClick={this.handleClick}> Login</a></span></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignUp;