import React from "react";
import { IntroVideo } from "components/IntroVideo";
import { Header } from "components/header";
import "./IntroVideoScreen.scss"
import { Button } from "components/button";
import { MovingfulExperienceCard } from "components/movingfulExperienceCard";

export interface IntroVideoScreenProps {
    path?: string;
}

export interface IntroVideoScreenState {

}

class IntroVideoScreen extends React.Component<IntroVideoScreenProps, IntroVideoScreenState> {
    constructor(props: IntroVideoScreenProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="video-screen-wrapper">
                <Header />
                <IntroVideo />
                <MovingfulExperienceCard/>
                <div className="video-btn">
                    <Button
                        text={"Let's Begin"}
                        width={"70vw"}
                    />
                </div>
                    </div>
         );
    }
}
 
export default IntroVideoScreen;