import { fetchGraphQL } from './graphqlService'

export async function getSponsorDetails(code: any) {
    var params = `query{
        getSponsorVideoURL(movingfulCode: "`+ code + `") 
        {
            firstName
           lastName
           introURL
           thankYouURL
         }
    
    }`;
  
    var response = await fetchGraphQL('POST', params);
   // console.log(response)
    return response;
  } 
