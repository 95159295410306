import React, { memo } from 'react'
import { useSpring, a, config } from 'react-spring'
// import { Home } from 'components/form';
import { Header } from "components/header"
import './MoveWithPage.scss'
import { MoveWithForm } from 'screens/moveWithForm';
import { MoveWithCard } from 'components/card/templates/moveWith';
import { CustomerInvitation } from 'screens/customerInvitation';
import { FormGroup, FormBuilder, FormControl } from 'react-reactive-form';
import { FormModel, IForm } from 'shared/model/form';
import { getEstimatorDetails } from 'core/services/movewithService';
import { IntroVideoScreen } from 'screens/introVideoScreen';

export interface MoveWithPageProps {
  form: any;
  fh: any;
  width: number;
  height: number;
  progress?: number;
  onBack?(): void
}

export interface MoveWithPageState {
  couponDates: string[];
}
//@ts-ignore
class MoveWithPage extends React.Component<MoveWithPageProps, MoveWithPageState> {
  // function MoveWithPage({ form, fh, width, height, onBack }: MoveWithPageProps) {

  // moveWithForm: FormGroup;
  // formModel: IForm;

  constructor(props: MoveWithPageProps) {
    super(props);
    this.state = {
      couponDates: []
    };
    getEstimatorDetails(localStorage.getItem("branchID"))
    .then(response => {
    //  debugger;
      this.setState({couponDates: response.CouponDates.split(',')});
    })
    // this.moveWithForm = getMoveWithFormGroup();
    // this.formModel = new FormModel(this.props.form.formJSON, this.moveWithForm);
  }

  render() {
    return (
      <a.div>
        <a.figure style={{ backgroundImage: `url(/imgs/${`99.jpg`})`, height: this.props.fh }}>
          {/* <Header progress={progress}/> */}
          {/* <MoveWithCard height={this.props.height} /> */}
          {/* <CustomerInvitation  /> */}
          <IntroVideoScreen/>
        </a.figure>
        <div style={{ zIndex: 2000 }}>
          <MoveWithForm 
            form={this.props.form} 
            couponDates={this.state.couponDates}
            width={this.props.width} 
            onBack={this.props.onBack} />
        </div>
      </a.div>
    )
  }
}

export default MoveWithPage;

// function phoneNumberValidator(formControl: FormControl) {
//   // validate USA phone number
//   if (!/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.exec(formControl.value)) {
//     return { phone: true }
//   } else {
//     return null;
//   }
// }

// function getMoveWithFormGroup(lead: any = {}) {

//   const { MoveType, MoveSize, MoveDate, FirstName, Email, Telephone } = lead;

//   return FormBuilder.group(
//     {
//       // MoveType: [MoveType, Validators.required],
//       // MoveSize: [MoveSize, Validators.required],
//       // MoveDate: [MoveDate, Validators.required],
//       // FirstName: [FirstName, Validators.required],
//       // Email: [Email, Validators.email],
//       Telephone: ['', phoneNumberValidator],
//       // done: ['']
//     }
//   );
// }