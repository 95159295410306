
import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react'
import { a, useSpring } from 'react-spring'
import { useDrag } from 'react-use-gesture'
// import data from '../../data'
// import { cardListJson as data } from '../../screens/list/data';
import { MoveWithCard } from './templates/moveWith';
import { ImageCard } from './templates/imageCard';
import { TemplateSelector } from '../templateSelector';
// import { cardListJson2 } from '../../screens/list/data2';

const Card = ({ index, onClick, height, width, data, className, style }: {index: any, onClick: any, height: number, width: number, data: any, className: any, style: any}, ref: any) => {
  // const { img, title, category } = data[index]
  const { img } = data;
  const cardRef = useRef(null)
  const [{ scale, visibility }, set] = useSpring(() => ({ scale: 1, visibility: 'visible' }))

  // Handles the scale of the card when the mouse presses the card.
  const bind = useDrag(({ down }) => set({ scale: down ? 0.92 : 1 }))

  // const [cardWidth, setCardWidth] = useState(width);

  // This hook will add convenience functions to the card ref when,accessed
  //from the Page component. This will let us write `cardRef.getStyle()`
  useImperativeHandle(ref, () => ({
    // Returns the starting style of the card. It accounts for the scale
    // applied to it when it's called.
    getStyle: (winWidth: any) => {
      const s = scale.getValue()
      //@ts-ignore
      const { width, height, x, y } = cardRef.current.getBoundingClientRect()

      // setCardWidth(width / s); // store card width so we can use it in html of this component

      return {
        width: width / s,
        height: height / s,
        // Since the animated container is itself contained in a flex wrapper
        // that centers its element, the x position should take its natural
        // position into account (winWidth - width) / 2 to measure how much it
        // should travel.
        x: x - (winWidth - width) / 2,
        y: y - (height / s - height) / 2,
        scale: s
      }
    },
    set
  }))

  let dimension = {
    height: height,
    width: width,
    color: img ? 'white' : 'black'
  }

  return (
    <a.div
      ref={cardRef}
      {...bind()}
      onClick={() => onClick(index)}
      className={className}
      //@ts-ignore
      style={{ ...style, visibility, backgroundImage: `url(/imgs/${img})`,
          borderRadius: 16, backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top', scale }}
      >
      <TemplateSelector template={data.template || "imageCard"} data={data} height={height}/>
    </a.div>
  )
}

export default forwardRef(Card)