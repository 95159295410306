import moment from 'moment';
const APIPRODCOUPON : string="https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMPCoupon";
const APIPRODNONCOUPON: string="https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMP02"

const APISTAGECOUPON: string="https://apistaging.allmysons.com/amsleadimport.asmx/ImportLeadPMPCoupon"
const APISTAGENONCOUPON: string="https://apistaging.allmysons.com/amsleadimport.asmx/ImportLeadPMP02"

const APIPRODCODE : string="https://api.allmysons.com/amsleadimport.asmx/ImportLeadMovingful";
const APISTAGECODE: string="https://apistaging.allmysons.com/amsleadimport.asmx/ImportLeadMovingful"

export function getEstimatorDetails(branchId: any){
    console.dir("calling details method")
    const url = 'https://sparrowwebservices.allmysons.com/Branch.asmx/GetBranchDetailsCouponDatesPricingMobilePage?BranchID=' 
    + branchId;

    return fetch(url)
        .then((resp) => resp.text()) // Transform the data into json
        .then(function(data) {
            var resp=data.replace('<?xml version="1.0" encoding="utf-8"?>','')
            .replace('<string xmlns="http://tempuri.org/">','')
            .replace('</string>','');
            var dt = JSON.parse(resp);
            var zip = dt[0].zip;
            localStorage.setItem("zip", zip)
            localStorage.setItem("branchID", branchId)
            localStorage.setItem("FirstName", dt[0].FirstName);
            localStorage.setItem("EmployeeImg", dt[0].EmployeeImg);
            localStorage.setItem("MoveCount", dt[0].mc);
           var dates = dt[0].CouponDates.split(',')
           localStorage.setItem("CouponDates", JSON.stringify(dates));
           console.dir(JSON.stringify( dates));
         //   debugger;
            console.dir(resp)
            return dt[0];
    })
}

export function saveLead(formObj: any) {
    console.dir(formObj);
    // read necessary values from formObject
    var movedate = formObj.MoveDate;

var coupon = localStorage.getItem("won")==="y"?1:0
    // if not sure selected , add 15 days
    if(movedate=="")
    {
        formObj.MoveDate = moment().add(15,"days").format("MM/DD/YYYY");
        localStorage.setItem("notsure" , "1");

    }
    else{
        localStorage.setItem("notsure" , "0")
    }

    var Email = formObj.Email;
    if (Email == "") {
        Email = "Didnotprovide@allmysons.com";
    }
   // debugger;
    var MoveDate = formObj.MoveDate;
    var FullName = formObj.FirstName;
    var Telephone = formObj.Telephone;
    Telephone = Telephone.replace('(', '').replace(')', '').replace(' ', '-').replace(' ', '-');
    var MoveSize = getMoveSizeFromTypeAndBedrooms(formObj.MoveSize, parseInt(formObj.MoveSizeDetail.toString())); // dbvalue;
  //  alert(MoveSize)
  var OriginZip = localStorage.getItem("currentZipCode");
    var MoveType = formObj.MoveType
    var Src = "ambassador"
    var Phone = Telephone;
    var formData = {
        FullName: FullName,
        Telephone: Phone,
        Email: Email,
        MoveDate: formObj.MoveDate,
        MoveSize: MoveSize,
        OriginZip : localStorage.getItem("currentZipCode"),
        MoveType: MoveType,
        Src: Src,
    }
    
    submitQuoteRequestForRefferal(formData.FullName, formData.Telephone, formData.Email, formData.MoveDate, formData.MoveSize, formData.OriginZip, "", formData.MoveType)
}

const getMoveSizeFromTypeAndBedrooms = (moveType: any, bedrooms: number) => {
    	//Accepts a string and an int.
console.log(moveType, bedrooms)
		switch(moveType) {
			case "House":
			  // code block
				if(bedrooms >= 4){
					return "House 4 Bedroom or more";
				} else if(isNaN(bedrooms)){return "House 2 Bedroom"
				} else {return "House " + bedrooms + " Bedroom"}
			case "Apartment":
			// code block
				if(bedrooms >= 2){
					return "Apt 2 Bedroom or more";
				} else if(isNaN(bedrooms)) {return "Apt 1 Bedroom"
				} else {return "Apt " + bedrooms + " Bedroom"}
			case "Condo":
				return "Condo";
			default:
				return "Other";
		}

}

// METHOD TO BIND VALUES TO API CALL
const submitQuoteRequest = (name: any, phone: any, email: any, date: any, size: any, origin: any, destination: any, type: any, coupon:number) => {
    var notsure = false;
    if (localStorage.getItem("notsure") === "1") {
        notsure = true;
    } else {
        notsure = false;
    }
    var Src = localStorage.getItem("Src")+"coupon";
    console.log(name);
    var temp = name.split(" ");
    var firstName = temp.shift();
    console.log(temp);
    var lastName = temp.join(" ");
    console.log(lastName);
    console.log("calling service");

    if (size === "House 4 Bedroom or more") {

        //this.callService("https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMP02", "FirstName=" + firstName
        callService(APISTAGECOUPON, "FirstName=" + firstName
            + "&LastName=" + lastName
            + "&Telephone=" + phone
            + "&Email=" + "Didnotprovide@allmysons.com"
            + "&MoveDate=" + date
            + "&MoveSize=" + size
            + "&OriginZip=" + localStorage.getItem("currentZipCode")
            + "&Src="
            + Src
            + "&DestinationZip=&APIReferenceID=1&CanWeText=true&MovingDateNotSure=" + notsure
            + "&ZipCodeNotSure=false&MoveType="
            + type
            + "&IsAutodialer=false"
            +"&CouponID=" + coupon
            //@ts-ignore
            , "", this);
    }
    else {
        //  callService("https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMP02",

        callService(APISTAGECOUPON,
            "FirstName=" + firstName
            + "&LastName=" + lastName
            + "&Telephone=" + phone
            + "&Email=" + "Didnotprovide@allmysons.com"
            + "&MoveDate=" + date
            + "&MoveSize=" + size
            + "&OriginZip=" + localStorage.getItem("currentZipCode")
            + "&Src="
            + Src
            + "&DestinationZip=&APIReferenceID=1&CanWeText=true&MovingDateNotSure=" + notsure
            + "&ZipCodeNotSure=false&MoveType="
            + type
            + "&IsAutodialer=true"
            +"&CouponID=" + coupon
            //@ts-ignore
            , "", this);
    }

}


// FOR NON-COUPON
const submitQuoteRequestForRefferal = (name: any, phone: any, email: any, date: any, size: any, origin: any, destination: any, type: any) => {
    var notsure = false;
    if (localStorage.getItem("notsure") === "1") {
        notsure = true;
    } else {
        notsure = false;
    }
    var Src = localStorage.getItem("Src");
    console.log(name);
    var temp = name.split(" ");
    var firstName = temp.shift();
    console.log(temp);
    var lastName = temp.join(" ");
    console.log(lastName);
    console.log("calling service");

        //this.callService("https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMP02", "FirstName=" + firstName
        callService(APISTAGECODE, "FirstName=" + firstName
            + "&LastName=" + lastName
            + "&Telephone=" + phone
           // + "&Telephone=9999999999"
            + "&Email=" + "Didnotprovide@allmysons.com"
            + "&MoveDate=" + date
            + "&MoveSize=" + size
            + "&OriginZip=" + localStorage.getItem("currentZipCode")
            + "&Src="
            + "Ambassador"
            + "&DestinationZip=&APIReferenceID=1&CanWeText=true&MovingDateNotSure=" + notsure
            + "&ZipCodeNotSure=false&MoveType="
            + type
            + "&IsAutodialer=false"
            + "&Referral="+localStorage.getItem("refferalCode")
            //@ts-ignore
            , "", this);
    
  

}

// METHOD TO BUILD XMLHttpRequest
const callService = (url: any, params: any, callback: any, scope: any) => {
    var http = new XMLHttpRequest();
    http.open("POST", url, true);
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    console.log("triggering call of service");
    console.log(url);
    console.log(params);
    http.onreadystatechange = function () {
        if (http.readyState === 4 && http.status === 200) {
            console.log("firing callback")
           // callback(http.responseText, scope);
           console.dir(http.responseText);
           localStorage.removeItem('movewith')
           localStorage.removeItem('notsure')

        }
    }
    http.send(params);
}