import React from "react";
import './CouponInformation.scss'


export interface CouponInformationProps {
  promoCode?: string;
  discountAmount?: string
}

export interface CouponInformationState {

}

class CouponInformation extends React.Component<CouponInformationProps, CouponInformationState> {
    constructor(props: CouponInformationProps) {
        super(props);
        this.state = {};
    }
    render() {


      const CouponText = {
          intro: "Your move date qualifies for",
          promoCode: this.props.promoCode,
          discountAmount: this.props.discountAmount,
          discountCondition: "off your move!",
          instruction: "Receive a text with your promo code:"
        };

        return (
          <div className="coupon-container">
              <div className="coupon-text">
                {CouponText.intro}
              </div>

              <div className="coupon-text discount">
                  <span className="discount-amount">{CouponText.discountAmount}</span>
                  <span className="discount-condition">{CouponText.discountCondition}</span>
              </div>

              <div className="coupon-text instruction">
              {CouponText.instruction}
              </div>
          </div>
        );
    }
}

export default CouponInformation;
