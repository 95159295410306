export async function fetchGraphQL(method: string, params: string) {
  var jsonData = null;
 //var endPoint = 'https://amsfanclubapi.allmysonsbeanstalk.com/graphql';//Production
  var endPoint = 'https://amsfanclubapi.allmysonsstagingbeanstalk.com/graphql/';//staging
  try {
      const fetchResult = fetch(endPoint, {
          method: method.toLocaleUpperCase(),
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ query: params }),
      });

      const response = await fetchResult;

      if (response.ok) {
          jsonData = await response.json();
      } else {
          jsonData = null;
          throw new Error(response.statusText);
      }
              return jsonData;
  }
  catch (err) {
      //network related errors
      console.error("fetchGraphQL:" + err);
      throw err;
  }
}