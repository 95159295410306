import React from "react";
import "./LayoutContainer.scss"

export interface LayoutContainerProps {
    children?: any;
}
 
export interface LayoutContainerState {
    
}
 
class LayoutContainer extends React.Component<LayoutContainerProps, LayoutContainerState> {
    constructor(props: LayoutContainerProps) {
        super(props);
        this.state = {   };
    }
    render() { 
        return ( 
            <div className="layout-container">
                {this.props.children}
            </div>
         );
    }
}
 
export default LayoutContainer;