import React from "react";
import "./CustomerInvitation.scss"
import { Button } from "components/button";
import { Header } from "components/header";
import { getSponsorDetails } from 'core/services/SponsorService'
import { IntroCard } from "components/introCard";
import { navigate } from "@reach/router";

export interface CustomerInvitationProps {
    path?: string;
}

export interface CustomerInvitationState {
    sponsorName: any;

}

class CustomerInvitation extends React.Component<CustomerInvitationProps, CustomerInvitationState> {
    private _isMounted: boolean = false;
    constructor(props: CustomerInvitationProps) {

        super(props);
        this.state = {
            sponsorName: ""
        };
    }
    async fetchData() {
        var code = localStorage.getItem("refferalCode") ? localStorage.getItem("refferalCode") : "JPMORGAN111";
        let response = await getSponsorDetails(code);
        console.log(response.data.getSponsorVideoURL[0]);
        localStorage.setItem("sponsorDetails", response.data.getSponsorVideoURL[0]);
        this.setState({ sponsorName: response.data.getSponsorVideoURL[0].firstName })
        localStorage.setItem("introVideo", response.data.getSponsorVideoURL[0].introURL)

    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toIntroVideo() {
        navigate('/intro');
    }

    render() {
        return (
            <div className="main-wrapper">
                <Header
                // progress={100}
                // leftIcon={'arrow-left'}
                // leftText={'Home'}
                // rightIcon={'phone'}
                // rightText={'Call Us'}
                // onLeftIconPress={onLeftIconPress}
                />
                <div className="page-heading">
                    <h1>Congratulations!</h1>
                </div>
                <div className="invitation-text">
                    <p>You’ve been invited to Movingful, where you can move well and do good!</p>
                </div>
                <IntroCard
                    introHeading={"moving.ful"}
                    introText={true}
                />
                {/* <div className="order-number">
                    <div className="circle-icon">1</div>
                    <div className="customer-info">
                        <p>Tell us about your move.</p>
                    </div>
                    <div className="circle-icon">2</div>
                    <div className="customer-info">
                        <p>Match with a verified pro mover and top crew.</p>
                    </div>
                    <div className="circle-icon">3</div>
                    <div className="customer-info">
                        <p>Have a well-orchastrated and perfect move!</p>
                    </div>
                </div> */}
                <div className="meet-movers">
                    <p> Meet {this.state.sponsorName},</p>
                    <p> your All My Sons Moving Specialist</p>
                </div>
                <div className="invitation-btn">
                    <Button
                        text={"Meet " + this.state.sponsorName}
                        width={"70vw"}
                        onClick={() => this.toIntroVideo()}
                    />
                </div>


            </div>
        );
    }
}

export default CustomerInvitation;