import React from "react";
import { MoveImage } from "components/image";
import { logo } from 'assets/images';
import './Login.scss';
import { TextField } from "components/textField";
import { Button } from "components/button";
//@ts-ignore
// import SimpleLineIcon from 'react-simple-line-icons';
import { FaFacebookF, FaGooglePlusG} from 'react-icons/fa';
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { navigate } from "@reach/router";


export interface LoginProps {
    path?: string;
    field?: any;
    onClick?: void;

}

export interface LoginState {

}

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {};
        clearAllBodyScrollLocks();
    }
    handleClick() {
        navigate('/signup')
       }
    render() {
        return (
            <div className="login-background">

            <div id="login-container">
                <div className="signin-logo">
                    <MoveImage className="logo" src={logo} width={'66.4%'}></MoveImage>
                </div>
                <div className="text-field">
                    <div className="email">
                        <TextField
                            label={'Email'}
                            keyboardType={'email'}
                        />
                    </div>
                    <div className="password">
                        <TextField
                            label={'Password'}
                            keyboardType={'password'}
                        />
                    </div>
                </div>
                <div className="login-button">
                    <Button
                        text={'Login'}
                        width={'77.3%'}
                        height={'7vh'}
                        fontSize={'2rem'}
                        fontWeight={'700'}
                    />
                </div>
                <div className="line-view">
                <div className="social-seperator"></div>
                    <div className="info-text">
                      <p> or Login with Social</p> 
                    </div>
                    <div className="social-seperator"></div>
                </div>
                <div className="social-btn">
                <div className="google"><FaGooglePlusG/></div>
                <Button
                        text={'Google'}
                        width={'95%'}
                        height={'7vh'}
                        fontSize={'1.8rem'}
                        backgroundColor={'#DC4E41'}
                        fontWeight={'bold'}
                        textTransform={'capitalize'}
                        
                    />
               <div className="facebook"><FaFacebookF/></div>
                    <Button
                        text={'Facebook'}
                        width={'95%'}
                        height={'7vh'}
                        fontSize={'1.8rem'}
                        backgroundColor={'#2553B4'}
                        fontWeight={'bold'}
                        textTransform={'capitalize'}
                       
                    />

                </div>

                <div className="signup-info">
                    <p>Don't have an account?<span className="signup-text"><a onClick={this.handleClick}> Signup</a></span></p>
                </div>
            </div>
            </div>
        );
    }
}

export default Login;