import React from "react";
import { CardLayout } from "components/cardLayout";
import { logo } from "assets/images"
import { SubHeading } from "components/subHeading";
import { PageHeading } from "components/pageHeading";
import "./IntroCard.scss"
import { audio } from 'assets/images';
import { audioTune } from 'assets/audio';

export interface IntroCardProps {
    introText?: any;
    style?: any;
    introHeading?: any;
    introHeading1?: any;

}

export interface IntroCardState {

}



class IntroCard extends React.Component<IntroCardProps, IntroCardState> {
    constructor(props: IntroCardProps) {
        super(props);
        this.state = {};

    }
    clickme = () => {
        const aud = new Audio(audioTune);
        aud.play();
    }
    render() {
        return (
            <CardLayout style={{ padding: '0em 3.5em', height: "47vh" }}>
                <div className="intro-heading">
                    {this.props.introHeading ?
                        (<h2>{this.props.introHeading}</h2>)
                        :
                        (<h3>{this.props.introHeading1}</h3>)


                    }

                </div>
                {this.props.introText &&
                    <div className="specialist-intro-text" style={{ ...this.props.style }}>
                        <div className="audio-icon">
                            <img src={audio} onClick={() => this.clickme()} alt="" />
                            <p><span>/ˈmuvɪŋfl/</span></p>
                        </div>
                        <span className="type-span">noun</span>
                        <p><span className="meaning-info1">a meaningful way to move</span></p>
                        <p><span className="meaning-info2">“I’m very thankful Susie, my realtor, recommended Movingful, as we had a wonderful experience and did good for others.”</span></p>
                        <p className="meaning-info3">Similar</p>
                        <p className="meaning-info3">Meaningful. Grateful. Thoughtful. Wonderful.</p>
                    </div>
                }
            </CardLayout>
        );
    }
}

export default IntroCard;