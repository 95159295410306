import React, { Component, useState, useEffect } from 'react'
import { FormControl } from 'react-reactive-form';
import './Thankyou.scss';
import { CountdownTimer } from '../../components/CountdownTimer'
import { MoveCounter } from '../../components/Thankyou/MoveCounter'
import { FormTitle } from '../../components/form/formTitle';
import { ConfirmationMessage } from '../../components/Thankyou/ConfirmationMessage';
import { Checklist } from '../../components/Thankyou/Checklist';
import { getEstimatorDetails } from 'core/services/movewithService'
import { Header } from 'components/header';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useStore } from '../../index';
import { ThankyouCard } from 'components/thankyouCard';
import { LayoutContainer } from 'components/layoutContainer';


declare global {
    interface Window { dataLayer: any; }
}

export interface ThankyouProps {
    formControl?: FormControl;
    path?: string;
    progress?: number;
}

export interface ThankyouState {
    header: any;
    bullets: any;
    cid: any;
    firstName: any;
    employeeImg: any;

}

const Thankyou = ({ formControl, path, progress }: ThankyouProps) => {
    const navigate = useStore((state: any) => state.navigate)
    const endNav = useStore((state: any) => state.endNav)

    const [firstName] = useState(localStorage.getItem("FirstName") || undefined);
    const [employeeImg] = useState(localStorage.getItem("EmployeeImg"));

    useEffect(() => {
        clearAllBodyScrollLocks();
        window.scrollTo(0, 0);
        //   getEstimatorDetails(9);
        window.dataLayer.push({
            event: 'pageview',
            page: { path: "/thanks" }
        })
    }, []);

    const onLeftIconPress = () => {
        navigate("/");
        setTimeout(() => {
            endNav()
        }, 400);
    }

    return (
        <LayoutContainer>
            <Header
                // progress={100}
                // leftIcon={'arrow-left'}
                // leftText={'Home'}
                // rightIcon={'phone'}
                // rightText={'Call Us'}
                // onLeftIconPress={onLeftIconPress}
            />
            <div className="page-title">
                <p>your suggested mover</p>
            </div>
            <ThankyouCard />

            <div className="bottom-text">
                <p>We have received your information and it is being reviewed by our Moving Specialist. We’ll call you soon to help you book your move.</p>
            </div>
        </LayoutContainer>
        // <div className="thankyou">


        /* <ConfirmationMessage name={firstName} variant="success" />

        <FormTitle title={"Calling you now"} subTitle={""}></FormTitle>

        <CountdownTimer />

        <MoveCounter /> */

        // </div>
    )

}

export default Thankyou;
