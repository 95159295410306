import * as React from 'react';
import { FormTitle } from 'components/form';

export interface IMovingfulCodeProps {

}

export default function MovingfulCode (props: IMovingfulCodeProps) {
    const [refferalCode, setrefferalCode] = React.useState(localStorage.getItem('refferalCode') || '')
  return (
    <div>
             <FormTitle
            backgroundColor={"white"}
            title={""}
            subTitle={''}
          />
    </div>
  );
}
