import React, { Component, useState, useEffect } from 'react'
import { FormControl } from 'react-reactive-form';
import './Coupon.scss';
// import { CountdownTimer } from '../../components/CountdownTimer'
import { FormTitle } from '../../components/form/formTitle';
// import { ConfirmationMessage } from '../../components/Thankyou/ConfirmationMessage';
// import { Checklist } from '../../components/Thankyou/Checklist';
// import { getEstimatorDetails } from '../../API/API'
import { Header } from 'components/header';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useStore } from '../../index';
import { TextField } from "components/textField";
import { Button } from "components/button";
import { CouponInformation } from 'components/couponInformation';


export interface CouponProps {
    formControl?: FormControl;
    path?: string;
    progress?: number;
    field?: any;
}

export interface CouponState {
    header: any;
    bullets: any;
    cid: any;
    firstName: any;
    employeeImg: any;


}

const Coupon = ({ formControl, path, progress, field }: CouponProps) => {
    const navigate = useStore((state: any) => state.navigate)
    const endNav = useStore((state: any) => state.endNav)

    const [firstName] = useState(localStorage.getItem("FirstName") || undefined);
    const [employeeImg] = useState(localStorage.getItem("EmployeeImg"));

    useEffect(() => {
        clearAllBodyScrollLocks();
        //   getEstimatorDetails(9);
    }, []);

    const onLeftIconPress = () => {
        navigate("/");
        setTimeout(() => {
            endNav()
        }, 400);
    }

    return (
        <div id="coupon">

            <Header
                progress={100}
                leftIcon={'home'}
                leftText={'Home'}
                rightIcon={'phone'}
                rightText={'Call Us'}
                onLeftIconPress={onLeftIconPress}
            />


            {/* <FormTitle title={"Your move date qualifies for"} subTitle={""}></FormTitle> */}

            <CouponInformation
              promoCode={'MOVE1005'}
              discountAmount={'50'}
            />

            <div>
                <TextField
                    label={'Name'}
                    formControl={new FormControl('')}
                    keyboardType={'name'}

                />

                <TextField
                    label={'Phone'}
                    formControl={new FormControl('')}
                    keyboardType={"phone"}
                />
            </div>
            <div>
                <Button
                    text={'Continue'}
                    width={'50vw'}
                    height={46}
                    fontSize={'1.8em'}
                />
            </div>

        </div>
    )

}

export default Coupon;
