import React from "react";
import "./CardLayout.scss"

export interface CardLayoutProps {
    style?: any;
}

export interface CardLayoutState {

}

class CardLayout extends React.Component<CardLayoutProps, CardLayoutState> {
    constructor(props: CardLayoutProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="card-container" style={{ ...this.props.style }}>

                {this.props.children}
                
            </div>
        );
    }
}

export default CardLayout;