import { availableMoveSizes, availableMoveTypes, availableMoveSizeDetails, availableMoveSizeDetailsApartment } from "./data";
import moment from "moment";

var today = moment().format("MM/DD/YYYY");
var tomorrow = moment().add(1, 'days').format("MM/DD/YYYY");
console.dir("XYZ------------"+today);

export const moveWithFormFields = (lead: any = {}, couponDates: any[] = []) => {
    return {
        "formPages": [
            // {
            //     "id": "",
            //     // "title": "congratulations",
            //     // "subTitle": "You have been invited",
            //     "key": "introvideo",
            //     "shortTitle": "",
            //     "goNextOnValid": true,
            //  //   "footer": true,
            //     "fields": [
            //         {
            //             "fieldType": "introVideo",
            //             // "required": true,
            //             "formControlName": "introVideo",
            //             "value":""
            //         },
            //         {
            //             "label": "Let's Begin",
            //             "fieldType": "button",
            //             "formControlName": "Introdone",
            //             "order": 2
            //         },
            //         // {
            //         //     "fieldType": "singleSelect",
            //         //     // "required": true,
            //         //     "formControlName": "MoveDate",
            //         //     "value": lead.MoveDate,
            //         //     "bindLabel": "label",
            //         //     "bindValue": "value",
            //         //     "options": [
            //         //         /* { "id": "", "label": "TODAY", "value": today },
            //         //         { "id": "", "label": "TOMORROW", "value": tomorrow  }, */
            //         //         { "id": "", "label": "I'M NOT SURE", "value": false }
            //         //       ]
            //         // },
            //     ]
            // },
            {
                "id": "",
                "title": "How far are we moving you?",
                "key": "move",
                "shortTitle": "",
                "goNextOnValid": true,
                "fields": [
                    {
                        "fieldType": "singleSelect",
                        "required": true,
                        "formControlName": "MoveType",
                        "value": lead.MoveType,
                        "bindLabel": "label",
                        "bindValue": "value",
                        "options": availableMoveTypes
                    },
                ]
            },
            {
                "id": "",
                "title": "We're moving your...",
                "key": "type",
                "shortTitle": "",
                "goNextOnValid": true,
                "fields": [
                    {
                        "fieldType": "singleSelect",
                        "required": true,
                        "formControlName": "MoveSize",
                        "value": lead.MoveSize,
                        "bindLabel": "value",
                        "bindValue": "value",
                        "options": availableMoveSizes
                    },
                ]
            },
            {
                "id": "",
                "title": "What size is your move?",
                "key": "size",
                "shortTitle": "",
                "goNextOnValid": true,
                "fields": [
                    {
                        "fieldType": "singleSelect",
                        "required": true,
                        "formControlName": "MoveSizeDetail",
                        "value": lead.MoveSizeDetail,
                        "bindLabel": "value",
                        "bindValue": "value",
                        "options": availableMoveSizeDetails
                    },
                ]
            },
            {
                "id": "",
                "title": "What Is Current Address?",
                "key": "address",
                "shortTitle": "",
                "goNextOnValid":false,
                "fields": [
                    {
                        "label": "Address",
                        "fieldType": "address",
                        "required": true,
                        "formControlName": "address",
                        "keyboardType": "text",
                        "value": "",
                        "order": 1
                      },
                    {
                        "label": "Continue",
                        "fieldType": "button",
                        "formControlName": "Addressdone",
                        "order": 2
                    },
                ]
            },
            {
                "id": "",
                "title": "Approximate Move Date",
                "subTitle": "You can easily change this later.",
                "key": "date",
                "shortTitle": "",
                "goNextOnValid": true,
                "footer": true,
                "fields": [
                    {
                        "fieldType": "datePicker",
                        // "required": true,
                        "formControlName": "MoveDate",
                        "value": lead.MoveDate
                    },
                    // {
                    //     "fieldType": "singleSelect",
                    //     // "required": true,
                    //     "formControlName": "MoveDate",
                    //     "value": lead.MoveDate,
                    //     "bindLabel": "label",
                    //     "bindValue": "value",
                    //     "options": [
                    //         /* { "id": "", "label": "TODAY", "value": today },
                    //         { "id": "", "label": "TOMORROW", "value": tomorrow  }, */
                    //         { "id": "", "label": "I'M NOT SURE", "value": false }
                    //       ]
                    // },
                ]
            },
            {
                "id": "",
                "title": "Let's Discuss Your Quote!",
                "key": "confirm",
                "shortTitle": "",
                "fields": [
                    {
                        "fieldType": "couponInfo",
                        "order": 1,
                        // isVisible: () => couponDates.indexOf(moment(lead.MoveDate).format('MM-DD-YYYY')) > -1
                    },
/*                     {
                        "fieldType": "refferalcode",
                        "value": {
                            "title": "",
                            'subTitle': ""
                        },
                        "order": 1, */
                        // isVisible: () => couponDates.indexOf(moment(lead.MoveDate).format('MM-DD-YYYY')) > -1
                   // },
                    {
                        "label": "First Name",
                        "fieldType": "textField",
                        "required": true,
                        "formControlName": "FirstName",
                        "value": lead.FirstName,
                        "order": 2
                    },
                    {
                        "label": "Phone",
                        "fieldType": "textField",
                        "formControlName": "Telephone",
                        "required": true,
                        "keyboardType": "tel",
                        "validators": {
                            // Regex for USA phone pattern validation
                            "custom": /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/
                        },
                        "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                        "value": lead.Telephone,
                        "order": 3
                    },
                    {
                        "label": "CONTINUE",
                        "fieldType": "button",
                        "formControlName": "done",
                        // "keyboardType": "tel",
                        // "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                        // "value": "",
                        "order": 4
                    },
                ]
            }
        ]
    }
}
