import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss';
// import { Match, navigate } from '@reach/router'
import { Router, Link, navigate, Match, Location } from "@reach/router";
import { Dialog } from '@reach/dialog';
import create from 'zustand';
import 'fonts/DINPro-Regular.woff';
import List from '././screens/list/List';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './theme/styles/styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MoveWithForm } from 'screens/moveWithForm';
import { Thankyou } from 'screens/thankyou';
import { Login } from 'screens/login';
import { SignUp } from 'screens/signup';
import { PopupScreen } from 'screens/popUpScreen';
import { getEstimatorDetails } from 'core/services/movewithService'


import '@reach/dialog/styles.css';
import { Coupon } from 'screens/coupon';
import { CustomerInvitation } from 'screens/customerInvitation';
import { IntroVideoScreen } from 'screens/introVideoScreen';


// We just use a store to augment the router navigation function so that it
// handles transition status and disable the body scroll during the transition
// (ie animation).

const [useStore] = create(set => ({
  status: 'idle',
  navigate: (address: any, domEl: any, state?: any) => {
    set({ status: 'transitioning' })
    if (domEl) disableBodyScroll(domEl)
    navigate(address, state)
  },
  endNav: () => {
    set({ status: 'idle' })
    clearAllBodyScrollLocks()
  }
}))

export { useStore }

function Routes(props: any) {
  return (
    <Router {...props}>
      <List path="/intro" />
      <List path="/intro/:index">
        {/* <PopupScreen path="/popup" /> */}
      </List>
      <PopupScreen path="/popup" />
      <Thankyou path="/thanks" />
      <Login path="/login"></Login>
      <SignUp path="/signup"></SignUp>
      <Coupon path="/coupon" />
      <CustomerInvitation path="/" />
      {/* <IntroVideoScreen path="/introVideoScreen" /> */}
    </Router>
  );
}

//@ts-ignore
const App = ({ children: any }) => {

  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    localStorage.clear();
    const params = new URLSearchParams(window.location.search);
		const phone = params.get('phone') || "8442070741"; //
    const Src = params.get('src') || "A-Web";
  //  const refferalCode = params.get('refferalCode') || "";
    const refferalCode = params.get('movingfulCode') || "JPMORGAN111";
    localStorage.setItem("refferalCode", refferalCode)
		localStorage.setItem("Src", Src);
		localStorage.setItem("phone",phone)
		const branchID =  params.get('branch') || "9";
    localStorage.setItem("branchID", branchID);
    localStorage.setItem("coupon","")
    getEstimatorDetails(branchID)

  //  getFormPages();
  }, []);

  return (
    <Location>
      {({ location, navigate }) => {
        //@ts-ignore
        const { oldLocation } = location.state || {};
        return (
          < >
            <Routes location={oldLocation != null ? oldLocation : location}/>
            <Dialog
              style={{zIndex: 9500}}
              isOpen={oldLocation != null}
              onDismiss={() => {
                navigate(oldLocation.pathname);
              }}
            >
              <Routes location={location} />
            </Dialog>
          </>
        );
      }}
    </Location>
  )
};



const rootElement = document.getElementById('root')
//@ts-ignore
ReactDOM.render(<App />, rootElement)
