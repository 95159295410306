import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Button as MaterialButton } from '@material-ui/core';
import './Button.scss'
import { IFormPage } from 'shared';
import { FormControl } from 'react-reactive-form';

//@ts-ignore
const ColorButton: any = withStyles(theme => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#00A79D',
    borderColor: '#00A79D;',
    boxShadow: 'none',
    textAlign: 'center',
    borderRadius: 100,
    // minHeight: 48,
    width: 'calc(100% - 30px)',
    maxWidth: '540px',
    borderWidth: 0,
    fontSize: "2rem",
    fontFamily: "'Heebo', sans-serif",
    textTransform: "uppercase",

    '&:hover': {
      backgroundColor: '#00A79D',
      borderColor: 'none',
      cursor: 'pointer',
      color: '#ffffff',
      borderWidth: 0,
      textTransform: "uppercase",
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 0,
      boxShadow: 'none',
    },


    rippleVisible: {
      opacity: 0.8
    },
  },

  "@keyframes mui-ripple-enter": {
    "0%": {
      transform: "scale(0)",
      opacity: 0.1
    },
    "100%": {
      transform: "scale(1)",
      opacity: 0.8
    }
  },
}))(MaterialButton);

interface ButtonProps {
  text?: string; 
  width?: any;
  height?: any; 
  fontSize?: any;
  backgroundColor?: any;
  fontWeight?: any; 
  textTransform?: any; 
  marginTop?: any; 
  paddingTop?: any; 
  formPage?: IFormPage; 
  formControl?: FormControl;
  children?: any;
  onClick?(): void;
}


export default function Button({ 
  text, width, height, fontSize, backgroundColor, 
  fontWeight, textTransform, marginTop, paddingTop, 
  formPage, formControl, onClick }: ButtonProps) {


  const handleClick = () => {
    if (formControl) {
      formControl.setValue(true);
    }
    if (onClick) {
      onClick();
    }
  }

  return (
    <div className="comfort-btn">
      <ColorButton
        variant='contained'
        disabled={formPage ? !formPage.valid : false}
        style={{ width: width, height: height, fontSize: fontSize, backgroundColor: backgroundColor, fontWeight: fontWeight, textTransform: textTransform, marginTop: marginTop, paddingTop: paddingTop }}
        onClick={() => handleClick()}
      >
        {text}
      </ColorButton>
    </div>
  );
}
