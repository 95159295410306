import React from "react";
import { CardLayout } from "components/cardLayout";
import "./MovingfulExperienceCard.scss"

export interface MovingfulExperienceCardProps {
    
}
 
export interface MovingfulExperienceCardState {
    
}
 
class MovingfulExperienceCard extends React.Component<MovingfulExperienceCardProps, MovingfulExperienceCardState> {
    constructor(props: MovingfulExperienceCardProps) {
        super(props);
        this.state = {  };
    }
    render() { 
        return ( 
            <CardLayout style={{padding: "2em 2em 2em"}}>
                    <div className="mover-heading">
                        <h2>Your Movingful Experience includes:</h2>
                    </div>
                    <div className="experience-list">
                        <ul>
                            <li>A Dedicated Moving Specialist</li>
                            <li>Top Crews, Always!</li>
                            <li> Flexible Pricing (Move Now, Pay Later)</li>
                            <li> Your Comfort, Our Promise</li>
                        </ul>
                    </div>
                    <div className="add-oppertunity">
                        <p>...and the opportunity to do good for others.</p>
                    </div>
                </CardLayout>
         );
    }
}
 
export default MovingfulExperienceCard;