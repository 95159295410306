import React from "react";
import ReactDOM from 'react-dom'
import './MoveWith.scss'
import {BrandAdverbs} from './BrandAdverbs';
//@ts-ignore
import Slider from "react-slick";
import { Header } from '../../../header';
import { useSpring, a, config } from 'react-spring'
import { Button } from "components/button";
import Animation from './Animation';
//import {BrandAdverbs} from './BrandAdverbs';
import { navigate } from '@reach/router';

export interface MoveWithCardProps {
  height?: any;
}

export interface MoveWithCardState {
  isAnimated?: boolean;
}

class MoveWithCard extends React.Component<MoveWithCardProps, MoveWithCardState> {
    slider: any;
    constructor(props: MoveWithCardProps) {
        super(props);
        this.state = {
          isAnimated: true
        };
    }

    componentDidMount() {
      let element: any = document.querySelector('main');
      let cardopen: any = element.classList.contains('frozen');
      let metaTag: any  = document.querySelector('meta[name="theme-color"]');

      if (cardopen === false) {
        let themeColor: any = metaTag.content ="#F3EFE4";
        console.log("the theme color is tan");
      }

    }

    onLeftIconPress() {
        navigate('/move');
    }

    stopAnimation() {
      this.setState({isAnimated: false});
    }



    render() {

        return (
            <a.div className="" style={{backgroundColor:'#F3EFE4', borderRadius:16, height: this.props.height}}>
            {/* <Header
                progress={undefined}
                leftIcon={'tag'}
                leftText={'pricing'}
                rightIcon={'phone'}
                rightText={'Call Us'}
                onLeftIconPress={() => this.onLeftIconPress()}
            /> */}
              <div className="main-content">
              </div>
              <div className="move-heading">
                <h1>Move</h1>
                <h2> With Us </h2>
              </div>

              {this.state.isAnimated ? (
                        <Animation brandAdverbs={BrandAdverbs()} stop={() => this.stopAnimation()}/>
                    ) : (
                      <div className="adverb-inner">
                        <div className="adverb static">
                          {BrandAdverbs()[0].img}
                        </div>
                      </div>
                    )}


              <Button text={"Get Comfortable"} />

          </a.div>
         );
    }
}

export default MoveWithCard;
