export function sendSMS(name: string, phone: string){
    console.log(name);
    var temp = name.split(" ");
    var firstName = temp.shift();
    console.log(temp);
    var lastName = temp.join(" ");
    console.log(lastName);
    console.log("calling service");
  var smsText = "Hi " + name + "! Here is $50 to make your move more comfortable";
  smsText = smsText + ". Simply show your driver the day of your move, and he will apply it to your payment.";
  
  var customerphone = phone.replace('(', '').replace(')', '').replace(' ', '-').replace(' ', '-');
  debugger;
  const url = 'https://twilioapi.allmysons.com/SendMessage.asmx/SendCoupon02?CustomerPhone=' 
           + phone
      + "&BranchID=" + localStorage.getItem("branchID")
            + "&Text=" + smsText
            fetch(url)
            .then((resp) => resp.text()) // Transform the data into json
            .then(function(data) {
              //  var resp=data.replace('<?xml version="1.0" encoding="utf-8"?>','')
               // .replace('<string xmlns="http://tempuri.org/">','')
               // .replace('</string>','');
               // var dt = JSON.parse(resp);
               // console.dir(resp)
        })
    
    
    }