import React, { forwardRef } from "react";
import { SingleSelect } from "components/singleSelect";
import { DatePicker } from "components/datePicker";
import { TextField } from "components/textField";
import { Button } from "components/button";
import { CouponInformation } from "components/couponInformation";
import { FormTitle } from "components/form/formTitle";
import MovingfulCode from "components/MovingFulCode";
import {IntroVideo} from 'components/IntroVideo'
import { LocationSearchInput } from "components/locationSearch"

const InputControlSelector = ({ field, formPage, onClick, ...props }: any) => {
  const selectControl = (fieldType: any) => {
    let view: any;
    switch (fieldType) {
      case 'singleSelect':
        //@ts-ignore
        view = <SingleSelect
          field={field}
          options={field.options}
          formControl={field.formControl}
          bindLabel={field.bindLabel}
          bindValue={field.bindValue}
        />
        break;
      case 'datePicker':
        view = <DatePicker formControl={field.formControl} />
        break;
      case 'textField':
        view = <TextField
          label={field.label}
          formControl={field.formControl}
          keyboardType={field.keyboardType}
          mask={field.mask}
        />
        break;
      case 'button':
        view = <Button
          text={field.label}
          formPage={formPage}
          formControl={field.formControl}
          onClick={onClick}
          width={'50vw'}
          height={46}
          fontSize={'1.92em'}
          marginTop={'1em'}
        />
        break;
      case 'couponInfo':
        //debugger;
        if (field.isVisible) {
          //  debugger;
          view = <CouponInformation
            promoCode={'MOVE1005'}
            discountAmount={'50'}
          />
        } else {
          view = null;
        }

        break;
      case 'title':
          view = <FormTitle
            backgroundColor={"white"}
            title={field.value.title}
            subTitle={field.value.subTitle}
          />

        break;
        
      case 'refferalcode' :
        view = <MovingfulCode/>
        break;
        // case 'introVideo':
        //   view = <IntroVideo />
        //   break;
          case 'address':
        view = <LocationSearchInput formControl={field.formControl} />
        break;
    }
    return view;
  }

  return (
    selectControl(field['fieldType'])
  )
}

export default InputControlSelector;
