import React from "react";
import "./ThankyouCard.scss"
import { CardLayout } from "components/cardLayout";
import { amslogo } from 'assets/images';

export interface ThankyouCardProps {
    
}
 
export interface ThankyouCardState {
    
}
 
class ThankyouCard extends React.Component<ThankyouCardProps, ThankyouCardState> {
    constructor(props: ThankyouCardProps) {
        super(props);
        this.state = {   };
    }
    render() { 
        return ( 
            <CardLayout>
               <div className="card-img">
                   <img src={amslogo} className="" alt="logo"/>
                </div> 
                <div className="card-heading">
                    <p>All My Sons Moving & Storage</p>
                </div>
                <div className="intro-text">
                    <p>All My Sons Moving & Storage has been in business for over 20 years and offers full-service moving for customers and businesses locally, across state lines, and even internationally.</p>
                </div>
            </CardLayout>
         );
    }
}
 
export default ThankyouCard;