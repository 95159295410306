import React, { Component, useState, useEffect } from 'react'
import { Modal, makeStyles, Theme, createStyles } from '@material-ui/core';

export interface PopupScreenProps {
    path?: string;

}

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles: any = makeStyles((theme: Theme) =>
    //@ts-ignore
    createStyles({
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

const PopupScreen = ({ path }: PopupScreenProps) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <h1 style={{zIndex: 12000}}>Habibi</h1>
    )
}

export default PopupScreen;